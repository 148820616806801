import { BaseWithFollowUsLayout } from "../layouts";
import { Box, Typography } from "@mui/material";
import { Container, InlineLink, NextPageWithLayout, TypoEffect404 } from "@akte-mord/common";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import React from "react";
import type { GetStaticProps } from "next";
import TypoImage404 from "../../public/typo-effects/404-typo-bg.jpg";

const ErrorPage: NextPageWithLayout = () => (
  <Container>
    <Box sx={{ maxWidth: "md", mx: "auto" }}>
      <Box sx={{ maxWidth: "sm", mx: "auto", mb: 8 }}>
        <TypoEffect404 imageSrc={TypoImage404.src} />
      </Box>
      <Typography variant="h1" textAlign="center">
        Entschuldigung
      </Typography>
      <Typography variant="body1" paragraph textAlign="center" sx={{ mt: 2 }}>
        Hier ist etwas gründlich schief gelaufen. Wir konnten die gesuchte Seite leider nicht finden.
        Bitt verwende die Navigation, um zu einer anderen Seite zu gelangen.
      </Typography>
      <Typography variant="body1" paragraph textAlign="center">
        Du kannst uns bei Fragen auch jederzeit unter{" "}
        <InlineLink href="mailto:mail@akte-mord.de">mail@akte-mord.de</InlineLink> erreichen.
      </Typography>
    </Box>
  </Container>
);

ErrorPage.getLayout = (page) => (
  <BaseWithFollowUsLayout
    title="404 – Seite nicht gefunden | Akte Mord® Cold Cases für Zuhause"
    description="Hier ist etwas gründlich schief gelaufen. Wir konnten die gesuchte Seite leider nicht finden."
    noindex={true}
  >
    {page}
  </BaseWithFollowUsLayout>
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale ? await serverSideTranslations(locale, ["c-common"], i18nConfig) : {}),
    },
  };
};

export default ErrorPage;
